
import { Vue, Component } from "vue-property-decorator";
import UserProfile from "@/pages/manage/users/zones/profile.vue";
import ResetPassword from "@/pages/manage/users/zones/reset-password.vue";
import Roles from "@/pages/manage/users/zones/roles.vue";
import Pets from "@/pages/manage/users/zones/pets/index.vue"
import {
  mdiAccount,
  mdiAccountEdit,
  mdiFormTextboxPassword,
  mdiLogout,
  mdiOrderAlphabeticalAscending,
  mdiAccountSupervisorOutline,
  mdiTextBoxCheckOutline, mdiPaw, mdiAccountMultiplePlusOutline, mdiAccountCheckOutline,
} from "@mdi/js";
import { UserObjectDataSource } from "@/data/Object/UserObjectDataSource";
import UserConsents from "@/pages/manage/users/zones/consents.vue";
import Invitations from "@/pages/manage/users/zones/invitations.vue";
import EventRegistrations from "@/pages/manage/users/zones/event-registrations.vue"

@Component({
  components: {
    UserProfile,
    ResetPassword,
    Roles,
    UserConsents,
    Invitations,
    EventRegistrations,
    Pets
  },
})
export default class Home extends Vue {
  loaded: boolean = false;
  dataSource: UserObjectDataSource = new UserObjectDataSource(
    +this.$route.params.id,
  );

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Пользователи",
        to: "/manage/user/",
        exact: true,
      },
      {
        text: this.dataSource.model?.fullName,
        disabled: true,
      },
    ];
  }

  items: any = [
    {
      id: "11527258-CE32-47C2-BB70-3CACF72491E3",
      caption: "Редактировать профиль",
      icon: "profile",
      link: `/manage/user/${this.$route.params.id}/user-profile`,
      enabled: true,
    },
    {
      id: "9F716C05-3C2B-40DB-AF21-02EAB34A4FBF",
      caption: "Сменить пароль",
      icon: "password",
      link: `/manage/user/${this.$route.params.id}/reset-password`,
      enabled: true,
    },
    {
      id: "1C980B38-F222-4A93-BC45-8E1E89C7D785",
      caption: "Роли",
      icon: "role",
      link: `/manage/user/${this.$route.params.id}/roles`,
      enabled: true,
    },
    {
      id: "132431C1-FE21-4AAD-A905-042BBD84D18C",
      caption: "Согласия",
      icon: "consent",
      link: `/manage/user/${this.$route.params.id}/user-consents`,
      enabled: true,
    },
    {
      id: "0C35562D-6A3C-4ADA-80CB-ED4D80F722CB",
      caption: "Приглашения",
      icon: "invitation",
      link: `/manage/user/${this.$route.params.id}/invitations`,
      enabled: true,
    },
    {
      id: "58964E70-60C5-4FD6-8401-0A11117CB50B",
      caption: "Регистрации",
      icon: "registration",
      link: `/manage/user/${this.$route.params.id}/event-registrations`,
      enabled: true,
    },
    {
      id: "F7D3A57C-B4C5-4162-A77C-15B15F7AE18C",
      caption: "Питомцы",
      icon: "pet",
      link: `/manage/user/${this.$route.params.id}/pets`,
      enabled: false,
    },
  ];
  icons = {
    profile: mdiAccountEdit,
    password: mdiFormTextboxPassword,
    account: mdiAccount,
    logout: mdiLogout,
    invitation:mdiAccountMultiplePlusOutline,
    order: mdiOrderAlphabeticalAscending,
    role: mdiAccountSupervisorOutline,
    consent: mdiTextBoxCheckOutline,
    registration:mdiAccountCheckOutline,
    pet: mdiPaw
  };

  get page() {
    return this.$route.params?.page;
  }

  async created() {
    await Promise.all([
      this.dataSource.getProfileImage(),
      this.dataSource.getRoles(),
    ]);
    this.loaded = true;
  }
}
