
import { Component, Ref, Vue } from "vue-property-decorator";
import moment from "moment";
import { ListDataSource } from "@/data/List/ListDataSource";
import { Filter } from "@/cms-services/filter";
import AppForm from "@/components/app-form/app-form.vue";
import { emailAddres, required } from "@/cms-services/consts";
import axios from "axios";
import {UserObjectDataSource} from "@/data/Object/UserObjectDataSource";
@Component({
  components: { AppForm },
})
export default class Invitations extends Vue {
  @Ref("appForm") appForm: any;
  profile: any;
  dialogVisible: boolean = false;
  moment = moment;
  userObjectDataSource: UserObjectDataSource = new UserObjectDataSource(
      +this.$route.params.id,
  );
  dataSource: ListDataSource = new ListDataSource({
    className: "invitation",
    config: {
      pageSize: 1000,
    },
  });
  headers: any = [
    {
      text: "Id",
      value: "id",
      sortable: true,
    },
    {
      text: "Дата создания",
      value: "createDate",
      sortable: true,
    },
    {
      text: "ФИО",
      value: "fullName",
      sortable: false,
    },
    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
    {
      align: "right",
      sortable: false,
      value: "actions",
    },
  ];
  countryIdCache: any;
  model: any = {
    userProfileId: null,
    templatePath: "Mail/Invite",
    subject: "Приглашение в систему",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    countryId: null,
    cityId: null,
  };
  fields: any = [
    {
      editor: "string",
      attrs: {
        type: "text",
        label: "Фамилия",
      },
      name: "lastName",
      validations: [required()],
    },
    {
      editor: "string",
      attrs: {
        type: "text",
        label: "Имя",
      },
      name: "firstName",
      validations: [required()],
    },

    {
      editor: "string",
      attrs: {
        type: "text",
        label: "Отчество",
      },
      name: "patronymic",
    },
    {
      editor: "string",
      attrs: {
        label: "Email",
        placeholder: "Введите Email",
        type: "email",
      },
      name: "email",
      validations: [required(), emailAddres()],
    },
    {
      editor: "string",
      attrs: {
        type: "text",
        label: "Мобильный телефон",
        mask: "+7(###) ###-##-##",
      },
      name: "mobilePhone",
      validations: [required()],
    },
    {
      editor: "auto-complete-select",
      attrs: {
        label: "Страна",
      },
      config: {
        getItems: "getCountries",
        labelKeyName: "name",
        valueKeyName: "id",
      },
      grid: { cols: 12, md: 6 },
      name: "countryId",
      validations: [required()],
    },
    {
      editor: "auto-complete-select",
      attrs: {
        label: "Город",
        hint: "Чтобы выбрать город выберите страну",
        persistentHint: true,
      },
      config: {
        getItems: "getCities",
        labelKeyName: "name",
        valueKeyName: "id",
      },
      grid: { cols: 12, md: 6 },
      name: "cityId",
      watchers: [
        {
          changeModel: "watchCountry",
          changeItems: "filterCities",
          changeAttrs: "activateOrDisactivateCity",
        },
      ],
      validations: [required()],
    },
  ];
  countryDataSource: ListDataSource = new ListDataSource({
    className: "region",
    config: {
      pageSize: 1000,
      orderFieldName: "Priority",
      orderFieldDirection: "Asc",
      filter: JSON.stringify([
        {
          fieldName: "regionTypeId",
          fieldValue: 1,
        },
      ]),
    },
  });
  cityDataSource: ListDataSource = new ListDataSource({
    className: "region",
    config: {
      pageSize: 10000,
      orderFieldName: "Priority",
      orderFieldDirection: "Asc",
    },
  });
  actions: any = {
    async watchCountry(model) {
      if (!!model.countryId) return;
      model.cityId = null;
    },
    filterCities: async (model: any) => {
      if (!model.countryId) return [];
      if (model.countryId == this.countryIdCache)
        return this.cityDataSource.items;

      this.countryIdCache = model.countryId;
      this.cityDataSource.config.filter = JSON.stringify([
        {
          fieldName: "parentId",
          fieldValue: model.countryId,
        },
      ]);
      await this.cityDataSource.get();
      return this.cityDataSource.items;
    },
    getCities: () => this.cityDataSource.items,
    getCountries: () => this.countryDataSource.items,
    activateOrDisactivateCity: (model: any) => {
      return !!model.countryId
        ? {
            label: "Город *",
          }
        : {
            label: "Город *",
            disabled: true,
            hint: "Чтобы выбрать город выберите страну",
            persistentHint: true,
          };
    },
  };
  $message: any;

  async add() {
    await this.clearForm();
    this.dialogVisible = true;

  }
  update(item) {
    Object.assign(this.model, item);
    this.dialogVisible = true;
  }

  async invite(item) {
    await axios.post(`/invitation/${item.id}/invite`);
    item.instanceStateId = 200;
    this.$message(`Приглашение успешно отправлено на почу ${item.email}`);
  }

  async clearForm() {
    await this.$nextTick();
    await this.appForm?.reset?.();
    this.model = {
      userProfileId: this.profile.id,
      templatePath: "Mail/Invite",
      subject: "Приглашение в систему",
      firstName: "",
      lastName: "",
      patronymic: "",
      mobilePhone:"",
      email: "",
      phone: "",
      countryId: null,
      cityId: null,
    };
  }
  async addInvitation() {
    const valid = await this.appForm.validate();
    if (!valid) {
      this.$message({
        text: "Есть ошибки",
        color: "danger",
      });
      return;
    }
    try {
      await axios.post(`/invitation/`, this.model);
      await this.dataSource.get();
      this.dialogVisible = false;
      await this.appForm.reset();
      this.$message("Приглашение успешно добавлено");
      this.clearForm()
    } catch (error) {
      this.$message({
        text: (error as any).response?.data?.message,
        color: "error",
      });
    }
  }

  async updateInvitation() {
    const valid = await this.appForm.validate();
    if (!valid) {
      this.$message({
        text: "Есть ошибки",
        color: "danger",
      });
      return;
    }
    try {
      await axios.put(`/invitation/${this.model.id}`, this.model);
      await this.dataSource.get();
      this.dialogVisible = false;
      await this.appForm.reset();
      this.clearForm()
      this.$message("Приглашение успешно Обновлено");
    } catch (error) {
      throw error;
    }
  }
  copyLink(invitation: any) {
    navigator.clipboard.writeText(
      `https://ds-world.ru/login/register?inviteGuid=${invitation.guid}`
    );
    this.$message(`Ссылка на приглашение успешно скопирована в буфер обмена`);
  }
  getInviteCaption(item) {
    switch (item.instanceStateId) {
      case 200:
        return "Пригласить повторно";
      case 1:
        return "Пригласить";
    }
  }
  getInstanceStateColor(instanceStateId): string {
    switch (instanceStateId) {
      case 200:
        return "info";
      case 1:
        return "warning";
      case 11:
        return "success";
      default:
        return "default";
    }
  }
  getInstanceStateCaption(instanceStateId): string {
    switch (instanceStateId) {
      case 200:
        return "Отправлен";
      case 1:
        return "Создан";
      case 11:
        return "Принят";
      default:
        return "default";
    }
  }
  async mounted() {
    await this.userObjectDataSource.get();
    this.profile = this.userObjectDataSource.model;
    this.model.userProfileId = this.profile.id;
    this.dataSource.config.filter = JSON.stringify([
      new Filter("userProfileId", this.profile.id),
    ]);
    await this.dataSource.get();
  }

  async created() {
    await this.countryDataSource.get();
  }
}
