
import moment from "moment";
import { Vue, Component } from "vue-property-decorator";
import { Filter } from "@/cms-services/filter";
import { ListDataSource } from "@/data/List/ListDataSource";
import {UserObjectDataSource} from "@/data/Object/UserObjectDataSource";

@Component
export default class Events extends Vue {
  loaded: boolean = false;
  userProfile:any
  userObjectDataSource: UserObjectDataSource = new UserObjectDataSource(
      +this.$route.params.id,
  );

  dataSource: ListDataSource = new ListDataSource({
    className: "EventRegistration",
    config: {
      pageIndex: 1,
      pageSize: 300
    }
  });
  $message: any;
  $confirm: any;
  $route: any;


  async remove(item: any) {
    const confirm = await this.$confirm(
      "Вы действительно хотите отменить регистрацию?"
    );
    if (!confirm) return;
    await this.dataSource.remove(item.id);
    this.$message("Успешно отменено");
  }

  getFormattedDate(date) {
    if (!moment(date).isValid()) return "";
    return moment(date).format("DD.MM.YYYY");
  }

  async created() {
    await this.userObjectDataSource.get();
    this.userProfile = this.userObjectDataSource.model;
    this.dataSource.config.filter = JSON.stringify([new Filter("userProfileId", this.userProfile.id)]);
    await this.dataSource.get();
    this.loaded = true;
  }
}
