
import { Vue, Component } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import {Filter} from "@/cms-services/filter";
import {required} from "@/cms-services/consts";
@Component
export default class Home extends Vue {
  dataSource = new ListDataSource({
    className: "pet",
    config: {
      pageIndex: 1,
      pageSize: 15,
      filter: JSON.stringify([
        new Filter("userProfileId", +this.$route.params.id)
      ])
    },
  });

  createItemModel = {
    userProfileId: +this.$route.params.id,
    caption: "",
    description: "",
  };

  createItemFields: any = [
    {
      editor: "string",
      name: "caption",
      config: {},
      attrs: {
        type: "text",
        label: "Заголовок",
      },
      validations: [required()],
    },
    {
      editor: "memo",
      name: "description",
      config: {},
      attrs: {
        type: "text",
        label: "Описание",
      },
    },
  ];

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Питомцы",
        disabled: true,
      },
    ];
  }
}
