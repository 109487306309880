
import { UserObjectDataSource } from "@/data/Object/UserObjectDataSource";
import {
  mdiDotsVertical,
  mdiFolder,
  mdiFolderOpen,
  mdiMagnify,
  mdiPen,
  mdiPlusCircleOutline,
  mdiTrashCan,
} from "@mdi/js";
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import AppForm from "@/components/app-form/app-form.vue";
import { getValue } from "@/cms-services/helpers";
@Component({
  components: { AppForm },
})
export default class UserRoles extends Vue {
  @Prop() dataSource!: UserObjectDataSource;
  @Ref("appForm") appForm!: AppForm;
  $message: any;
  $confirm: any;
  icons: any = {
    pen: mdiPen,
    trash: mdiTrashCan,
    search: mdiMagnify,
    dotsVertical: mdiDotsVertical,
    circlePlus: mdiPlusCircleOutline,
    folderOpen: mdiFolderOpen,
    folder: mdiFolder,
  };

  model: any = {
    newPassword: "",
    newPasswordConfirmation: "",
  };

  fields: any = [
    {
      editor: "password",
      attrs: {
        outlined: true,
        label: "Введите новый пароль",
      },
      name: "newPassword",
      validations: [
        {
          validator: "required",
          errorMessage: "Поле не может быть пустым",
        },
      ],
    },
    {
      editor: "password",
      attrs: {
        outlined: true,
        label: "Подтвердите новый пароль",
      },
      name: "newPasswordConfirmation",
      validations: [
        {
          validator: "required",
          errorMessage: "Поле не может быть пустым",
        },
        {
          validator: "like",
          custom: true,
          value: "newPassword",
          errorMessage: "Не совподает с ведённым паролем",
        },
      ],
    },
  ];

  customValidators: any = {
    like: (field: any, info: any, model: any): Promise<string> => {
      const value = getValue(model, field.name);
      const targetValue = getValue(model, info.value);
      return new Promise<string>((resolve, reject) => {
        value === targetValue
          ? resolve(info?.successMessage)
          : reject(info.errorMessage);
      });
    },
  };

  async resetPassword() {
    const valid: boolean = await this.appForm.validate();
    if (!valid) return;
    try {
      await this.dataSource.resetPassword(this.model.newPassword);
      this.$message("Пароль успешно сброшен");
    } catch (error: any) {
      this.$message(error.response.data, "error");
    }
  }
}
